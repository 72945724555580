<template>
  <div class="card pb-4">
    <div class="card-header border-bottom-0">
      <form class="d-flex align-items-center">
        <input type="search" v-model="filter.lesson_name" class="form-control me-4"
               placeholder="Tên bài">
        <select class="form-control me-4" v-model="filter.is_reviewed">
          <option value="">-- Trạng thái --</option>
          <option value=0>Chưa đánh giá</option>
          <option value=1>Đã đánh giá</option>
        </select>
        <select class="form-control" v-model="filter.reviewed_by" v-if="user && (user.role === 'admin' || user.role === 'reviewer_admin')">
          <option value="">-- Người chấm --</option>
          <option :value="user.id" v-for="user in assignReviewerTeachers" :key="'reviewed' + user.id">{{ user.name }}</option>
        </select>
      </form>
    </div>
    <table class="table mb-4 list">
      <thead class="table-light">
      <tr>
        <th scope="col">Bài nộp</th>
        <th scope="col" v-if="role !== 'pupil'">Học viên</th>
        <th scope="col">Tên bài</th>
        <th scope="col">Đánh giá</th>
        <th scope="col" v-if="role === 'reviewer'">Trường học</th>
        <th scope="col">Trạng thái</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(submit, index) in selfData" :key="'submit' + index">
        <td class="align-middle">
          <div class="thumbnail" v-if="submit.media">
            <video controls class="max-height-100">
              <source :src="submit.media.link" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </td>
        <td class="align-middle" v-if="role !== 'pupil'">
          <div class="d-flex align-items-center" v-if="submit.user">
            <router-link class="text-nowrap"
                         :to="{name: 'AdminEditUser', params: {uid: submit.user.uid}}">
              <img
                  :src="submit.user.thumbnail ? submit.user.thumbnail.link : '/assets/images/avatar/avatar.jpg'"
                  alt="" class="rounded-circle avatar-xs me-2">
            </router-link>
            <router-link class="text-nowrap"
                         :to="{name: 'AdminEditUser', params: {uid: submit.user.uid}}">
              <h5 class="mb-0">{{ submit.user.name }}</h5>
            </router-link>
          </div>
        </td>
        <td class="align-middle">
          <span class="d-block"> {{ submit.lesson_name }}</span>
        </td>
        <td class="align-middle">
          <div class="d-flex mb-2" v-if="submit.comment">
            <div class="card rounded-top-md-end-0 bg-primary">
              <div class="card-body text-start p-2">
                <p class="mb-0 text-white text-wrap">
                  {{ submit.comment }}
                </p>
              </div>
            </div>
          </div>

          <div class="d-flex align-items-center" v-if="submit.reviewer">
            <img
                :src="submit.reviewer.thumbnail ? submit.reviewer.thumbnail.link : '/assets/images/avatar/avatar.jpg'"
                alt="" class="rounded-circle avatar-xs me-2">
            <h5 class="mb-0">{{ submit.reviewer.name }}</h5>
          </div>
        </td>
        <td class="align-middle" v-if="role === 'reviewer'">
          <a :href="'https://' + submit.school.subdomain + '.5seschool.vn'" v-if="submit.school">
            {{ submit.school.name }}
          </a>
        </td>
        <td class="align-middle">
          <div class="d-flex align-items-center">
                          <span class="badge rounded-pill bg-danger"
                                v-if="submit.is_reviewed === 0">Chưa đánh giá</span>
            <span class="badge rounded-pill bg-success" v-if="submit.is_reviewed === 1">Đã đánh giá</span>
            &nbsp;<i v-if="role !== 'pupil'" v-on:click="setCurrentSubmit(submit)" class="fe fe-plus cursor-pointer"
                     data-bs-toggle="modal" data-bs-target="#addSubmitLessonModal"/>
          </div>
        </td>
        <td class="align-middle text-muted align-middle border-top-0" v-if="user && (user.role === 'admin' || user.role === 'reviewer_admin')">
                            <span class="dropdown dropstart">
                              <a class="text-muted text-decoration-none" href="#" role="button" id="submitDropdown"
                                 data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>
                              <span class="dropdown-menu" aria-labelledby="submitDropdown" style="">
                                <span class="dropdown-header">Thao tác</span>
                                <a class="dropdown-item" v-on:click="setCurrentSubmit(submit)"
                                   href="#" data-bs-toggle="modal"
                                   data-bs-target="#assignReviewerModal"><i
                                    class="fe fe-user-check dropdown-item-icon"></i>Gán người đánh giá</a>
                                <a class="dropdown-item" v-on:click="setCurrentSubmit(submit)"
                                   href="#" data-bs-toggle="modal"
                                   data-bs-target="#removeSubmitLessonModal"><i
                                    class="fe fe-trash-2 dropdown-item-icon"></i>Xóa</a>
                              </span>
                            </span>
        </td>
      </tr>
      </tbody>
    </table>
    <v-pagination
        v-if="selfPaginatorInfo.lastPage > 1"
        v-model="selfPaginatorInfo.currentPage"
        :pages="selfPaginatorInfo.lastPage"
        :range-size="1"
        active-color="#DCEDFF"
        @update:modelValue="currentPageChange"
    />
  </div>

  <div class="modal fade" id="addSubmitLessonModal" tabindex="-1" role="dialog"
       aria-labelledby="addSubmitLessonModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <form v-on:submit.prevent="addReview">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="addSubmitLessonModalLabel">
              Thêm đánh giá
            </h4>
            <button id="addSubmitLessonModalClose" type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <video controls :key="'video' + currentSubmit.id" class="w-100">
                  <source :src="currentSubmit.media.link" type="video/mp4">
                  Your browser does not support the video tag.
                </video>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                     <textarea placeholder="Viết đánh giá..." rows="8" class="form-control"
                               v-model="currentSubmit.comment"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button class="btn btn-primary" type="submit">Thêm đánh giá</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal fade" id="removeSubmitLessonModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Xóa bài nộp
          </h4>
          <button type="button" id="removeSubmitLessonModalClose" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <p>Bạn có chắc muốn xóa bài nộp này?</p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button class="btn btn-danger" type="button"
                  v-on:click="removeSubmit">
            Xóa bài nộp
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="assignReviewerModal" tabindex="-1" role="dialog"
       aria-labelledby="assignReviewerModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <form v-on:submit.prevent="assignReviewer">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="assignReviewerModalLabel">
              Gán đánh giá
            </h4>
            <button id="assignReviewerModalClose" type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-4">
              <label class="form-label" for="reviewed_by">Chọn người được gán</label>
              <select class="form-control" v-model="assignReviewerUID" id="reviewed_by">
                <option :key="'teacher' + teacher.uid" v-for="teacher in assignReviewerTeachers"
                        :value="teacher.uid">{{ teacher.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button class="btn btn-primary" type="submit">Gán đánh giá</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <Modal id="submitMessageModal" :type="messageModalType" :message="messageModalMessage" :title="messageModalTitle"
         :message-detail="messageModalMessageDetail"/>
</template>

<script>
import Modal from "@/components/Modal";
import ApiService from "@/core/services/api.service";
import {closeModal, openModal} from "@/core/services/utils.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {getUser} from "@/core/services/jwt.service";

export default {
  name: "SubmitLesson",
  components: {
    Modal,
    VPagination
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    paginatorInfo: {
      type: Object,
      default() {
        return {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    },
    role: String
  },
  data() {
    return {
      selfData: [],
      selfPaginatorInfo: {
        currentPage: 1,
        lastPage: 0,
        total: 0
      },
      filter: {
        name: "",
        email: "",
        phone: "",
        lesson_name: "",
        is_reviewed: "",
        reviewed_by: ""
      },
      messageModalType: "",
      messageModalMessage: "",
      messageModalTitle: "",
      messageModalMessageDetail: "",
      currentSubmit: {id: null, comment: "", media: {link: ""}},
      assignReviewerUID: null,
      assignReviewerTeachers: [],
      user: null
    }
  },
  created() {
    this.selfPaginatorInfo = this.paginatorInfo;
  },
  watch: {
    data: {
      handler(newVal) {
        this.selfData = newVal;
      },
      deep: true
    },
    filter: {
      handler() {
        this.filterChange();
      },
      deep: true
    },
    paginatorInfo: {
      handler(newVal) {
        this.selfPaginatorInfo = newVal;
      },
      deep: true
    }
  },
  methods: {
    setCurrentSubmit(submit) {
      this.currentSubmit.id = submit.id;
      this.currentSubmit.comment = submit.comment;
      this.currentSubmit.media = submit.media;
    },
    addReview() {
      this.messageModalTitle = "Thêm đánh giá";

      let query = `mutation($id: ID!, $comment: String!) {
        reviewSubmitLesson(id: $id, comment: $comment) {
          id
        }
      }`;

      ApiService.graphql(query, this.currentSubmit)
          .then(({data}) => {
            if (data.data && data.data.reviewSubmitLesson) {
              this.messageModalMessage = "Thêm đánh giá thành công";
              this.messageModalType = "success";
              closeModal("addSubmitLessonModal");
              openModal("submitMessageModal");
              this.dataChange();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("submitMessageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("submitMessageModal");
          });
    },
    assignReviewer() {
      this.messageModalTitle = "Gán đánh giá";

      let query = `mutation($submit_id: ID!, $uid: ID) {
        assignReviewer(submit_id: $submit_id, uid: $uid)
      }`;

      ApiService.graphql(query, {submit_id: parseInt(this.currentSubmit.id), uid: this.assignReviewerUID})
          .then(({data}) => {
            if (data.data && data.data.assignReviewer) {
              this.messageModalMessage = "Gán thành công";
              this.messageModalType = "success";
              closeModal("assignReviewerModal");
              openModal("submitMessageModal");
              this.dataChange();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("submitMessageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("submitMessageModal");
          });
    },
    removeSubmit() {
      this.messageModalTitle = "Xóa bài nộp";

      let query = `mutation($id: ID!) {
        deleteSubmitLesson(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, this.currentSubmit)
          .then(({data}) => {
            if (data.data && data.data.deleteSubmitLesson) {
              this.messageModalMessage = "Xóa bài nộp thành công";
              this.messageModalType = "success";
              closeModal("removeSubmitLessonModal");
              openModal("submitMessageModal");
              this.dataChange();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("submitMessageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("submitMessageModal");
          });
    },
    filterChange() {
      this.$emit("filterChange", this.filter);
    },
    currentPageChange() {
      this.$emit("currentPageChange", this.paginatorInfo.currentPage);
    },
    dataChange() {
      this.$emit("dataChange");
    },
    loadTeachers() {
      let query = `query {
        mySchool {
          users(first: 1000, where: {AND: [{column:"school_user.role", value:"teacher"}]}) {
            data {
              name
              phone
              email
              uid
            }
          }
        }
      }`;

      ApiService.graphql(query, {code: this.$route.params.code})
          .then(({data}) => {
            if (data.data && data.data.mySchool && data.data.mySchool.users && data.data.mySchool.users.data) {
              this.assignReviewerTeachers = data.data.mySchool.users.data;
              this.assignReviewerTeachers.map((user) => {
                if (user.phone) {
                  user.name = user.name + " - " + user.phone;
                }
                if (user.email) {
                  user.name = user.name + " - " + user.email;
                }
              });
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadReviewers() {
      let query = `query {
        users(first: 1000, where: {AND: [{column:"role", value:"reviewer"}]}) {
          data {
            name
            phone
            email
            uid
            id
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.users && data.data.users.data) {
              this.assignReviewerTeachers = data.data.users.data;
              this.assignReviewerTeachers.map((user) => {
                if (user.phone) {
                  user.name = user.name + " - " + user.phone;
                }
                if (user.email) {
                  user.name = user.name + " - " + user.email;
                }
              });
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
  },
  mounted() {
    if (this.role === "reviewer") {
      this.loadReviewers();
    } else {
      this.loadTeachers();
    }
    
    this.user = getUser();
  }
}
</script>
